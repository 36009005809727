<template>
  <div class="my-order">
    <div class="user-panel-title-box">
      <h2>Orders</h2>
      <!--        <h6 style="margin-top: 4px">We offer the following payment methods</h6>-->
    </div><!-- end user-panel-title-box -->
<!--    <div class="profile-setting-panel-wrap" v-loading="loading">-->
<!--      <div class="row gy-3">-->
<!--        <div class="col-12 col-md-6" v-for="it in records" :key="it.id">-->
<!--          <div class="item">-->
<!--            <div class="item-top">-->
<!--              &lt;!&ndash;              <img src="../images/order-img.png" alt="">&ndash;&gt;-->
<!--              <img :src="it.presignedUrl" alt="">-->
<!--              <div class="">-->
<!--                <router-link :to="'/product-details?id=' + it.plan.id">-->
<!--                  {{ it.plan.name }}-->
<!--                </router-link>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="item-content font-weight-bold">-->
<!--              <div class="row ">-->
<!--                <div class="col-4">-->
<!--                  <div class="value">-->
<!--                    <p class="p1">Amount</p>-->
<!--                    <p class="p2">-->
<!--                      ${{ it.amount }}-->

<!--                    </p>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-4">-->
<!--                  <div class="value">-->
<!--                    <p class="p1">Net Profit</p>-->
<!--                    <p class="p2">${{ it.netProfit }}</p>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-4">-->
<!--                  <div class="value">-->
<!--                    <p class="p1">Period(Days)</p>-->
<!--                    <p class="p2">{{ getDays(it) }}</p>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-6">-->
<!--                  <div class="value">-->
<!--                    <p class="p1">Start time</p>-->
<!--                    <p class="p2">{{ getTime(it.startTime) }}</p>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-6">-->
<!--                  <div class="value">-->
<!--                    <p class="p1">End time</p>-->
<!--                    <p class="p2">{{ getTime(it.endTime) }}</p>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->

<!--            </div>-->
<!--            <div class="item-footer">-->

<!--              <span class="badge text-bg-danger " v-if="it.status === 0"> Expire </span>-->
<!--              <span class="badge text-bg-success" v-else> Active </span>-->

<!--              &lt;!&ndash;            <b-badge v-if="it.status == 0" variant="danger">Expire</b-badge>&ndash;&gt;-->
<!--              &lt;!&ndash;            <b-badge v-else variant="success">Active</b-badge>&ndash;&gt;-->
<!--            </div>-->
<!--          </div>-->

<!--        </div>-->
<!--      </div>-->

<!--      &lt;!&ndash; pagination &ndash;&gt;-->
<!--      <div class="text-center mt-4 mt-md-5">-->
<!--        <Pagination :records="totalCount" v-model="transForm.pageNum" :per-page="transForm.pageSize"></Pagination>-->
<!--      </div>-->
<!--    </div>-->

    <div class="profile-setting-panel-wrap" v-loading="loading">
      <div class="table-responsive">
        <table class="table mb-0 table-s2">
          <thead class="fs-14">
          <tr>
            <th scope="col" class="text-center" v-for="(list, i) in tableHead" :key="i">{{ list }}</th>
          </tr>
          </thead>
          <tbody class="table-fs">
          <tr v-for="it in records" :key="it.id">
            <!--            <th scope="row"><a href="#">{{ it.id }}</a></th>-->
            <td class="text-center">
              <span class="badge text-bg-danger " v-if="it.status === 0"> Expire </span>
              <span class="badge text-bg-success" v-else>
                Active
              </span>
<!--              <p style="font-weight: 500;font-size: 12px;">Time left: {{ countdown[it.id]}}</p>-->
            </td>
<!--            <td class="text-center small">{{ countdown[it.id]}}</td>-->
            <td class="text-center" style="font-weight: 500;font-size: 16px;">
              ${{ it.amount }}&nbsp;/ {{ getDays(it) }} {{ getDays(it) === '1' ? 'Day' : 'Days' }}
              <br/>
              {{ it.plan.name }}

            </td>

            <td class="text-center small">{{ it.quantity }}</td>
            <td class="text-center small">${{ it.amount }}</td>
            <td class="text-center small">${{ it.netProfit }}</td>
            <td class="text-center small">{{ getTime(it.createdAt) }}</td>
            <td class="text-center small">{{ getTime(it.endTime) }}</td>
          </tr>
          </tbody>
        </table>
      </div><!-- end table-responsive -->
      <!-- pagination -->
      <div class="text-center mt-4 mt-md-5">
        <Pagination :records="totalCount" v-model="transForm.pageNum" :per-page="transForm.pageSize"></Pagination>
      </div>
    </div><!-- end profile-setting-panel-wrap-->

  </div>
</template>

<script>
import planApi from "@/apis/planApi";
import date from "@/date";
import Pagination from "v-pagination-3";

export default {
  name: "MyOrder",
  components: {
    Pagination
  },
  data() {
    return {
      loading: false,
      records: [],
      transForm: {
        pageNum: 1,
        pageSize: 5
      },
      totalCount: 0,
      totalPages: 1,
      tableHead: [
         'Status', 'Contract', 'Quantity', 'Amount', 'Net Profit','Order Time', 'End Time'
      ],
      countdown:  {}
    }
  },
  watch: {
    'transForm.pageNum': function () {
      this.getTable()
    }
  },
  mounted() {
    this.getTable()
    this.timer = setInterval(() => this.updateCountdown(), 1000);
  },
  unmounted() {
    clearInterval(this.timer);
  },
  methods: {
    getTable() {
      this.loading = true;
      planApi.mine(this.transForm, res => {
        this.loading = false;
        console.log(res)
        this.records = res.data?.page.result || [];
        this.totalCount = res.data?.page.totalCount || 0;
        this.totalPages = res.data?.page.totalPages || 1;
        // if (this.trans.result.length <= this.transForm.pageSize) this.transLoad = -1
      })
    },
    getDate(data) {
      return date.getDate(data)
    },
    getTime(data) {
      return date.getTime(data)
    },
    search() {
      this.transForm.pageNum = 1;
      this.totalCount = 0;
      this.totalPages = 0;
      this.records = [];
      this.getTable()
    },
    getDays(ob) {
      const mistiming = this.formatDateTimeSecond(ob)
      if (mistiming > 0) {
        var days = Math.floor(mistiming / 1000 / 60 / 60 / 24)
      }
      return `${days}`
    },

    formatDateTimeSecond(ob) {
      const start_date = ob.startTime
      const end_date = ob.endTime
      if (start_date === '' || !start_date) {
        return ''
      }
      if (end_date === '' || !end_date) {
        return ''
      }
      const date1 = new Date(start_date);
      const date2 = new Date(end_date);
      return date2.getTime() - date1.getTime()
    },
    updateCountdown() {
      const currentTime = new Date();
      if (!this.records || this.records.length === 0) {
        return;
      }
      this.records.forEach(it => {
        const endTime = new Date(it.endTime);
        const timeDiff = endTime - currentTime;
        if (timeDiff > 0) {
          const totalSeconds = Math.floor(timeDiff / 1000);
          const days = Math.floor(totalSeconds / (60 * 60 * 24));
          const hours = Math.floor((totalSeconds % (60 * 60 * 24)) / (60 * 60));
          const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
          const seconds = totalSeconds % 60;
          const formattedHours = hours.toString().padStart(2, '0');
          const formattedMinutes = minutes.toString().padStart(2, '0');
          const formattedSeconds = seconds.toString().padStart(2, '0');
          const formattedTime = `${days} Days ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
          this.countdown[it.id] = formattedTime;
        } else {
          this.countdown[it.id] = '0';
        }
      });
    }
  }

}
</script>

<style scoped lang="scss">
.table-fs {
  font-size: 0.975rem;
}


.my-order {
  .item {
    //width: 338px;
    //height: 264px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    transition: all 0.3s ease;
    //cursor: pointer;
    &:hover {
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
    }

    &-top {
      display: flex;
      align-items: center;
      height: 80px;
      padding: 0 15px;
      position: relative;

      &::after {
        content: "";
        bottom: -0.5px;
        left: 15px;
        right: 15px;
        height: 1px;
        opacity: 0.13;
        background-color: #979797;
        position: absolute;
      }

      img {
        width: 83px;
        margin-right: 5px;
      }

      div {
        flex: 1;
        width: 0;
        font-size: 16px;
        //font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #1C2A46;
        line-height: 22px;
      }
    }

    &-content {
      position: relative;
      padding: 0 15px;

      .value {
        padding: 10px 0;
        font-size: 14px;
        //font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #1C2A46;
        line-height: 20px;

        .p1 {

        }

        .p2 {
          margin-top: 4px;
        }
      }

      &::after {
        content: "";
        bottom: -0.5px;
        left: 15px;
        right: 15px;
        height: 1px;
        background-color: #979797;
        opacity: 0.13;
        position: absolute;
      }
    }

    &-footer {
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 15px;
    }
  }
}
</style>
